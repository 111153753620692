import React, { Component } from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Fab, Paper, Slide, ButtonBase, Grid, Box } from '@material-ui/core';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import BlackCursorIcon from '../../assets/img/large-cursor-black.png';
import WhiteCursorIcon from '../../assets/img/large-cursor-white.png';

interface IVisuallyImparedHelperProps {
  classes?: any;
}

interface IVisuallyImparedHelperState {
  open?: boolean;
  greyFilter?: boolean;
  invertFilter?: boolean;
  textZoom: number | null;
  cursor: string;
}

const styles = (theme: Theme) =>
  createStyles({
    floatingButton: {
      position: 'fixed',
      right: '1%',
      bottom: '2.5%',
      zIndex: 9999,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    paperWrap: {
      position: 'fixed',
      bottom: '50px',
      right: '50px',
      zIndex: 8888,
    },
    paper: {
      maxWidth: '320px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    gridItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'none',
    },
  });

class VisuallyImparedHelper extends Component<
  IVisuallyImparedHelperProps,
  IVisuallyImparedHelperState
> {
  state = {
    open: false,
    greyFilter: false,
    invertFilter: false,
    cursor: '',
    textZoom: null,
  };

  bodyClasses = document.body.classList;
  htmlDoc = document.querySelector('html');

  toggleModal = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  toggleGreyFilter = () => {
    this.setState((prevState) => ({ greyFilter: !prevState.greyFilter }));
  };

  toggleInvertFilter = () => {
    this.setState((prevState) => ({ invertFilter: !prevState.invertFilter }));
  };

  setLargeCursor = (cursor) => {
    this.bodyClasses.remove(`visibility__large-cursor--${this.state.cursor}`);
    if (cursor === this.state.cursor) {
      this.setState({ cursor: '' });
    } else {
      this.setState({ cursor });
    }
  };

  handleTextZoom = (zoom) => {
    if (!this.htmlDoc) {
      return;
    }
    this.htmlDoc.classList.remove(
      `visibility__text-zoom-${this.state.textZoom}`
    );
    if (this.state.textZoom === zoom) {
      this.setState({ textZoom: null });
    } else {
      this.htmlDoc.classList.add(`visibility__text-zoom-${zoom}`);
      this.setState({ textZoom: zoom });
    }
  };

  resetAll = () => {
    this.setState({ greyFilter: false });
    this.setState({ invertFilter: false });
    this.setState({ textZoom: null });
    this.setState({ cursor: '' });
    this.bodyClasses.value = '';
    this.htmlDoc!.classList.value = '';
  };

  render() {
    const { classes } = this.props;
    const { open, greyFilter, invertFilter, textZoom, cursor } = this.state;
    const filterClass = greyFilter ? 'visibility__grey-contrast' : '';
    const invertClass = invertFilter ? 'visibility__color-invert' : '';
    const cursorClass = cursor ? `visibility__large-cursor--${cursor}` : '';

    return (
      <div>
        <div
          className={`${invertClass} ${filterClass} ${cursorClass}`}
          style={{ backgroundColor: '#fff', minHeight: '100vh' }}
        >
          {this.props.children}
        </div>
        <Fab
          color="primary"
          aria-label="accessibility"
          className={classes.floatingButton}
          onClick={this.toggleModal}
        >
          <AccessibilityNewIcon />
        </Fab>
        <Slide direction="left" in={open} mountOnEnter unmountOnExit>
          <Paper className={classes.paperWrap}>
            <div className={classes.paper}>
              <Grid container spacing={4}>
                <Grid item xs={6} className={classes.gridItem}>
                  <Box bgcolor={greyFilter ? 'secondary.main' : ''}>
                    <ButtonBase onClick={this.toggleGreyFilter}>
                      <VisibilityOffIcon style={{ fontSize: 60 }} />
                      <p>Черно-белая страница</p>
                    </ButtonBase>
                  </Box>
                </Grid>
                <Grid item xs={6} className={classes.gridItem}>
                  <Box bgcolor={invertFilter ? 'secondary.main' : ''}>
                    <ButtonBase onClick={this.toggleInvertFilter}>
                      <InvertColorsIcon style={{ fontSize: 60 }} />
                      <p>Инверсия цветов</p>
                    </ButtonBase>
                  </Box>
                </Grid>
                <Grid item xs={6} className={classes.gridItem}>
                  <Box bgcolor={cursor === 'black' ? 'secondary.main' : ''}>
                    <ButtonBase onClick={() => this.setLargeCursor('black')}>
                      <img src={BlackCursorIcon} width="60px" alt="cursor" />
                      <p>Большой черный курсор</p>
                    </ButtonBase>
                  </Box>
                </Grid>
                <Grid item xs={6} className={classes.gridItem}>
                  <Box bgcolor={cursor === 'white' ? 'secondary.main' : ''}>
                    <ButtonBase onClick={() => this.setLargeCursor('white')}>
                      <img src={WhiteCursorIcon} width="60px" alt="cursor" />
                      <p>Большой белый курсор</p>
                    </ButtonBase>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4} className={classes.gridItem}>
                  <Box bgcolor={textZoom === 1 ? 'secondary.main' : ''}>
                    <ButtonBase onClick={() => this.handleTextZoom(1)}>
                      <TextFieldsIcon style={{ fontSize: 35 }} />
                    </ButtonBase>
                  </Box>
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                  <Box bgcolor={textZoom === 2 ? 'secondary.main' : ''}>
                    <ButtonBase onClick={() => this.handleTextZoom(2)}>
                      <TextFieldsIcon style={{ fontSize: 50 }} />
                    </ButtonBase>
                  </Box>
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                  <Box bgcolor={textZoom === 3 ? 'secondary.main' : ''}>
                    <ButtonBase onClick={() => this.handleTextZoom(3)}>
                      <TextFieldsIcon style={{ fontSize: 65 }} />
                    </ButtonBase>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} className={classes.gridItem}>
                  <ButtonBase onClick={this.resetAll}>
                    <Box fontSize={34}>СБРОСИТЬ</Box>
                  </ButtonBase>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Slide>
      </div>
    );
  }
}

export default withStyles(styles)(VisuallyImparedHelper);
