import { TextField, TextFieldProps } from '@material-ui/core';
import { useField } from 'formik';

const FormikTextField = ({
  name,
  ...props
}: TextFieldProps & { name: string }) => {
  const [field] = useField(name);

  return <TextField {...props} {...field} />;
};

export default FormikTextField;
