import React from 'react';
import { getMainPageContent } from '../../api/admin';
import useOnMount from '../../hooks/useOnMount';

type HomePageState = {
  main_text: string;
  description: string;
  programms: any[];
};

const HomePage = () => {
  const [content, setContent] = React.useState<HomePageState>({
    main_text: '',
    description: '',
    programms: [],
  });

  useOnMount(() => {
    getMainPageContent()
      .then((res) => setContent(res.data))
      .catch((err) => console.log(err));
  });

  return (
    <>
      <div className="container">
        <section className="intro">
          <div className="intro__text">
            <h1 className="intro__title">{content.main_text}</h1>
            <p className="intro__subtitle">{content.description}</p>
          </div>
          {content.programms
            .sort((a, b) => a.program_order - b.program_order)
            .map((p) => (
              <div className="intro__specialty" key={p.id}>
                <p className="specialty__title">{p.name}:</p>
                <div className="specialty__items-wrap">
                  {p.areas &&
                    p.areas
                      .sort((a, b) => {
                        if (a.video_link === null) {
                          return 1;
                        }

                        if (b.video_link === null) {
                          return -1;
                        }

                        if (a.video_link === b.video_link) {
                          return 0;
                        }

                        return a.video_link < b.video_link ? -1 : 1;
                      })
                      .map((a) => (
                        <div className="specialty__item">
                          {a.link ? (
                            <a
                              href={a.link}
                              className="title"
                              key={a.id}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {a.name}
                            </a>
                          ) : (
                            <p className="title" key={a.id}>
                              {a.name}
                            </p>
                          )}

                          {a.video_link && (
                            <iframe
                              width="500"
                              height="266"
                              src={a.video_link}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                              style={{ marginTop: '20px' }}
                            ></iframe>
                          )}
                        </div>
                      ))}
                </div>
              </div>
            ))}
        </section>
      </div>
    </>
  );
};

export default HomePage;
