import React, { lazy, Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from './api/history';

import './assets/public_scss/style.scss';

import CustomPage from './components/CustomPage';
import HomePage from './views/PublicPages/HomePage';
import HeaderPublicPage from './views/PublicPages/HeaderPublicPage';
import VisuallyImparedHelper from './utils/VisuallyImparedHelper';
import Preloader from './components/Preloader';

const InfoPage = lazy(() => import('./views/PublicPages/InfoPage'));
const ProgramsPage = lazy(() => import('./views/PublicPages/ProgramsPage'));
const TrainersPage = lazy(() => import('./views/PublicPages/TrainersPage'));
const ContactsPage = lazy(() => import('./views/PublicPages/ContactsPage'));

const PublicPages = () => {
  return (
    <>
      <VisuallyImparedHelper>
        <HeaderPublicPage />
        <Suspense fallback={<Preloader />}>
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/programs" component={ProgramsPage} />
              <Route path="/trainers" component={TrainersPage} />
              <Route path="/info" component={InfoPage} />
              <Route path="/custom-page/:id" component={CustomPage} exact />
              <Route path="/contacts" component={ContactsPage} />
            </Switch>
          </Router>
        </Suspense>
      </VisuallyImparedHelper>
    </>
  );
};

export default PublicPages;
