import { createStyles, makeStyles } from '@material-ui/core';
import { ErrorMessage, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getUserData, userLogin } from '../api/auth';
import { history } from '../api/history';
import { userInfoFetchSuccess } from '../store/actions/user';
import FormikPasswordField from './FormikPasswordField';
import FormikTextField from './FormikTextField';

const useStyles = makeStyles((theme) =>
  createStyles({
    usernameMargin: {
      marginTop: '8px',
    },
    passwordMargin: {
      marginTop: '36px',
    },
  })
);

const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialValues = { username: '', password: '' };
  const onSubmit = (values, { setSubmitting, setFieldError }) => {
    userLogin(values.username, values.password)
      .then(() => {
        getUserData()
          .then((res) => {
            if (!res) {
              return;
            }

            dispatch(userInfoFetchSuccess(res.data));

            if (!res.data.extra.consent_processing_pd) {
              history.push('/filling-out-personal-data');
              return;
            }

            if (res.data.is_superuser) {
              history.push('/admin/users');
            } else if (
              res.data.groups.filter((g) => g.name === 'coach').length
            ) {
              history.push('/courses');
            } else {
              history.push('/my-courses');
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          toast.error(error.response.statusText);
        } else if (error.response && error.response.status === 400) {
          for (const [field, err] of Object.entries(error.response.data)) {
            setFieldError(field, err as string);
          }
        } else if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail);
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form action="#">
        <FormikTextField
          className={classes.usernameMargin}
          name="username"
          type="text"
          label="Логин"
        />
        <ErrorMessage name="username" className="error" component="span" />
        <FormikPasswordField
          className={classes.passwordMargin}
          name="password"
        />
        <ErrorMessage name="password" className="error" component="span" />
        <button className="form__button" type="submit">
          Войти
        </button>
      </Form>
    </Formik>
  );
};

export default LoginForm;
