import {
  MY_COURSES_FETCH_SUCCESS,
  MY_COURSES_NEXT_PAGE,
  COURSE_CREATE_SUCCESS,
} from '../constants';

export function myCourses(state: any = {}, action) {
  switch (action.type) {
    case MY_COURSES_FETCH_SUCCESS:
      return { ...action.data };
    case MY_COURSES_NEXT_PAGE:
      return {
        ...action.data,
        results: [...state.results, ...action.data.results],
      };
    case COURSE_CREATE_SUCCESS:
      if (state.results && state.results.length) {
        return {
          ...state,
          results: [...state.results, action.courseDetail],
        };
      } else {
        return {
          ...state,
          results: [action.courseDetail],
        };
      }

    default:
      return state;
  }
}
