import { ITEMS_HAS_ERRORED, ITEMS_IS_LOADING } from '../constants';

export function hasErrored(bool) {
  return {
    type: ITEMS_HAS_ERRORED,
    hasErrored: bool,
  };
}

export function isLoading(bool) {
  return {
    type: ITEMS_IS_LOADING,
    isLoading: bool,
  };
}
