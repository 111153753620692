import store from 'store';
import API from './config';
import { UserGetData } from './types';

export function userLogin(username, password) {
  return API.post(`/users/token/`, { username, password }).then((res) => {
    if (!res) {
      return;
    }
    store.set('token', res.data.access);
    store.set('refreshToken', res.data.refresh);
  });
}

export function getUserData() {
  return API.get<UserGetData>('/users/my_data/', {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function createUserPersonalData(values: any) {
  return API.post(
    '/users/personal_data_processing/form/',
    { ...values },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  );
}
