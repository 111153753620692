import { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField, useFormikContext } from 'formik';
import { getAddresses } from '../api/addresses';

const FormikAddressSearchField = ({
  name,
  className,
  label,
}: TextFieldProps & { name: string }) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();
  const [list, setList] = useState<string[]>([]);

  useEffect(() => {
    if (field.value && field.value.length > 2) {
      const delay = setTimeout(() => {
        getAddresses(field.value)
          .then((res) => {
            setList(res);
          })
          .catch((e) => console.log(e));
      }, 500);
      return () => clearTimeout(delay);
    }
  }, [field.value]);

  return (
    <Autocomplete
      className={className}
      id="address-hint"
      options={list.map((item) => item)}
      fullWidth
      freeSolo
      filterOptions={(options, state) => options}
      onChange={(e, value) => setFieldValue(name, value)}
      renderInput={(params) => (
        <TextField {...params} {...field} label={label} />
      )}
    />
  );
};

export default FormikAddressSearchField;
