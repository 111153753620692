import React from 'react';

import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PrivateRoute } from './PrivateRoute';
import { StoreState } from './store/types';

export const PrivateRouteWithCondition = ({
  component: Component,
  noHeader,
  noNav,
  noContainer,
  condition,
  ...rest
}) => {
  const PageWithCondition = () => {
    const user = useSelector((state: StoreState.All) => state.user);

    return (
      <>
        {isEmpty(user) ? (
          <></>
        ) : condition() ? (
          <Component />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h2
              style={{
                fontSize: '40px',
                marginTop: '40px',
                textAlign: 'center',
                marginBottom: '40px',
              }}
            >
              Нет доступа к этой странице!
            </h2>
            <Link
              style={{
                border: '1px solid grey',
                borderRadius: '10px',
                padding: '10px',
              }}
              to="/"
            >
              На главную
            </Link>
          </div>
        )}
      </>
    );
  };

  return (
    <PrivateRoute
      component={PageWithCondition}
      noContainer={noContainer}
      noNav={noNav}
      noHeader={noHeader}
      {...rest}
    />
  );
};
