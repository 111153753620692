import store from 'store';
import API from '../../api/config';
import { history } from '../../api/history';
import { userInfo } from '../constants';
import { hasErrored, isLoading } from './generalActionCreators';

export function userInfoFetchSuccess(user) {
  return {
    type: userInfo.USER_INFO_FETCH_SUCCESS,
    user,
  };
}

export function userInfoLogoutSuccess() {
  return {
    type: userInfo.USER_LOGOUT,
    payload: null,
  };
}

export const userInfoFetch = (url) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const token = store.get('token');
      if (!token) {
        return;
      }

      let res = await API.get(url, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      dispatch(userInfoFetchSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(hasErrored(true));
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const userLogout = () => {
  return (dispatch) => {
    dispatch(userInfoLogoutSuccess());
    store.remove('token');
    store.remove('refreshToken');
    history.push('/login');
  };
};
