import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../api/history';
import LoginForm from '../components/LoginForm';
import { StoreState } from '../store/types';

const Login = () => {
  const user = useSelector((store: StoreState.All) => store.user);

  useEffect(() => {
    if (!isEmpty(user) && user.extra.consent_processing_pd) {
      history.push('/courses');
    }

    if (!isEmpty(user) && !user.extra.consent_processing_pd) {
      history.push('/filling-out-personal-data');
    }
  }, [user]);

  return (
    <React.Fragment>
      <div className="overlay" data-class="login-overlay">
        <div className="login" data-class="login">
          <div className="login__inner">
            <h1 className="login__title">Авторизация в личном кабинете</h1>
            <p>
              Для авторизации необходимо ввести логин и пароль (доменный/от
              рабочего компьютера), выданный вам ранее
            </p>
            <LoginForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
