import axios from 'axios';

const DADATA_KEY = '4881ee2eca0e8c85709fdf55ec14b4802ca25b85';

export function getAddresses(query) {
  return axios
    .post(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
      { query },
      {
        headers: {
          Authorization: `Token ${DADATA_KEY}`,
        },
      }
    )
    .then((res) => res.data.suggestions.map((item) => item.value));
}
