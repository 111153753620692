import {
  COURSE_DETAIL_FETCH_SUCCESS,
  COURSE_DETAIL_UPDATE_SUCCESS,
} from '../constants';

export function courseDetail(state = {}, action) {
  switch (action.type) {
    case COURSE_DETAIL_UPDATE_SUCCESS:
    case COURSE_DETAIL_FETCH_SUCCESS:
      return action.courseDetail;
    default:
      return state;
  }
}
