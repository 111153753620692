import { userInfo } from '../constants';

export function user(state = {}, action) {
  switch (action.type) {
    case userInfo.USER_INFO_FETCH_SUCCESS:
      return action.user;
    case userInfo.USER_LOGOUT:
      return {};
    default:
      return state;
  }
}
