import { configureStore } from '@reduxjs/toolkit';
import { user } from './reducers/userReducer';
import { myCourses } from './reducers/myCoursesReducer';
import { chapters } from './reducers/chaptersReducer';
import { courseDetail } from './reducers/courseDetailReducer';
import userWuthQuizResultsReducer from './reducers/usersWithQuizResultsReducer';
import { hasErrored, isLoading } from './reducers';

export const store = configureStore({
  reducer: {
    hasErrored,
    isLoading,
    user,
    myCourses,
    chapters,
    courseDetail,
    usersWithQuizResults: userWuthQuizResultsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
