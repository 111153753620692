import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField, useFormikContext } from 'formik';

const organizationsList = [
  'АО «Уфанет»',
  'ООО «Связьстройсервис»',
  'ООО «Связьоптикстрой»',
  'ООО «Авантис»',
  'АО «Фирма Мир»',
  'ООО «Фирма БИС»',
  'ООО «Тренинговый центр СКИЛЛ»',
  'ООО «РА Центр Творчества»',
  'ООО «ИРИДА»',
  'Ишимбайский филиал АО «Уфанет»',
  'Нефтекамский филиал АО «Уфанет»',
  'Оренбургский филиал АО «Уфанет»',
  'Орский филиал АО «Уфанет»',
  'Октябрьский филиал АО «Уфанет»',
  'Стерлитамакский филиал АО «Уфанет»',
  'Салаватский филиал АО «Уфанет»',
  'Казанский филиал АО «Уфанет»',
  'Нижегородский филиал АО «Уфанет»',
  'Йошкар-Олинский филиал АО «Уфанет»',
  'Московский филиал АО «Уфанет»',
];

const FormikSelectField = ({
  name,
  className,
  label,
}: TextFieldProps & { name: string }) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  return (
    <Autocomplete
      className={className}
      id="organization-name"
      options={organizationsList.map((item) => item)}
      fullWidth
      freeSolo
      onChange={(e, value) => setFieldValue(name, value)}
      renderInput={(params) => (
        <TextField {...params} {...field} label={label} />
      )}
    />
  );
};

export default FormikSelectField;
