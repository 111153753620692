import { lazy, Suspense } from 'react';
import { Link, Route, Router, Switch } from 'react-router-dom';
import { history } from './api/history';

import './assets/scss/style.scss';

import Login from './views/Login';

import { useSelector } from 'react-redux';
import Preloader from './components/Preloader';
import { PrivateRoute } from './PrivateRoute';
import { PrivateRouteWithCondition } from './PrivateRouteWithCondition';
import { StoreState } from './store/types';
import PersonalDataForm from './views/PersonalDataForm';

const Paperbase = lazy(() => import('./views/Admin/Paperbase'));

const CreateCourse = lazy(() => import('./views/CreateCourse'));
const CreateChapter = lazy(() => import('./views/CreateChapter'));
const CreateLesson = lazy(() => import('./views/CreateLesson'));
const CreateQuestion = lazy(() => import('./views/CreateQuestion'));

const EditCourse = lazy(() => import('./views/EditCourse'));
const EditChapter = lazy(() => import('./views/EditChapter'));
const EditLesson = lazy(() => import('./views/EditLesson'));
const EditQuestion = lazy(() => import('./views/EditQuestion'));
const EditCourseSettings = lazy(() => import('./views/EditCourseSettings'));

const StudentsExamsListPageContainer = lazy(
  () => import('./views/StudentsExamsListPage/StudentsExamsListPageContainer')
);
const StudentExam = lazy(() => import('./views/StudentExams'));

const MyCourses = lazy(() => import('./views/MyCourses'));

const StMyCourses = lazy(() => import('./views/Student/StMyCourses'));
const StAllCourses = lazy(() => import('./views/Student/StAllCourses'));

const StCourseDetail = lazy(() => import('./views/Student/StCourseDetail'));
const StChapterDetail = lazy(() => import('./views/Student/StChapterDetail'));
const StLessonDetail = lazy(() => import('./views/Student/StLessonDetail'));
const StChapterQuiz = lazy(() => import('./views/Student/StChapterQuiz'));
const StCourseQuiz = lazy(() => import('./views/Student/StCourseQuiz'));
const OtherCourses = lazy(() => import('./views/OtherCourses'));

const PrivatePages = () => {
  const user = useSelector((store: StoreState.All) => store.user);
  document.querySelector('html')!.classList.value = ''; // Удаляем классы которые вешает модуль для слабовидящих
  return (
    <Suspense fallback={<Preloader />}>
      <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route
            path="/filling-out-personal-data"
            component={PersonalDataForm}
          />

          <PrivateRoute path="/courses" component={MyCourses} exact />
          <PrivateRoute path="/other-courses" component={OtherCourses} exact />
          <PrivateRoute path="/courses/:id" component={StCourseDetail} exact />
          <PrivateRoute
            path="/other-courses/:id"
            component={StCourseDetail}
            exact
          />
          <PrivateRoute
            path="/courses/:courseId/chapters/:id"
            component={(props) => <StChapterDetail match={props.match} />}
            exact
          />
          <PrivateRoute
            path="/courses/:courseId/chapters/:chaptersId/lessons/:id"
            component={(props) => <StLessonDetail match={props.match} />}
            exact
          />
          <PrivateRoute path="/my-courses" component={StMyCourses} />
          <PrivateRoute path="/all-courses" component={StAllCourses} />
          <PrivateRouteWithCondition
            path="/admin"
            component={Paperbase}
            noHeader
            noNav
            noContainer
            condition={() => user.is_superuser}
          />

          <PrivateRoute
            path="/quizes/chapters/:id"
            component={(props) => <StChapterQuiz match={props.match} />}
          />

          <PrivateRoute
            path="/quizes/courses/:id"
            component={(props) => <StCourseQuiz match={props.match} />}
          />
          <PrivateRoute
            component={() => <CreateCourse />}
            path="/create-courses"
          />
          <PrivateRoute
            path="/edit-courses/:id"
            component={(props) => <EditCourse match={props.match} />}
            exact
          />
          <PrivateRoute
            path="/edit-courses/:id/settings"
            component={EditCourseSettings}
          />
          <PrivateRoute
            path="/courses/:courseId/create-chapters"
            component={(props) => <CreateChapter match={props.match} />}
          />
          <PrivateRoute
            path="/courses/:courseId/edit-chapters/:id"
            component={(props) => <EditChapter match={props.match} />}
          />
          <PrivateRoute
            path="/chapters/:chapterId/create-lessons"
            component={(props) => <CreateLesson match={props.match} />}
          />
          <PrivateRoute
            path="/courses/:courseId/chapters/:chapterId/edit-lessons/:id"
            component={(props) => <EditLesson />}
            exact
          />
          <PrivateRoute
            path="/lessons/:lessonId/create-questions"
            component={(props) => <CreateQuestion />}
          />
          <PrivateRoute
            path="/lessons/:lessonId/edit-questions/:id"
            component={(props) => <EditQuestion />}
          />
          <PrivateRoute
            exact
            path="/exams"
            component={StudentsExamsListPageContainer}
          />
          <PrivateRoute path="/exams/:id" component={StudentExam} />
          <Route
            render={() => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h2
                  style={{
                    fontSize: '40px',
                    marginTop: '40px',
                    textAlign: 'center',
                    marginBottom: '40px',
                  }}
                >
                  Такой страницы не существует!
                </h2>
                <Link
                  style={{
                    border: '1px solid grey',
                    borderRadius: '10px',
                    padding: '10px',
                  }}
                  to="/"
                >
                  На главную
                </Link>
              </div>
            )}
          />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default PrivatePages;
