import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { history } from './api/history';
import Header from './components/Header';
import Nav from './components/Nav';
import { StoreState } from './store/types';

interface Props extends RouteProps {
  component: any;
  noHeader?: boolean;
  noNav?: boolean;
  noContainer?: boolean;
  path?: string;
  exact?: boolean;
  location?: any;
}

export const PrivateRoute = ({
  component: Component,
  noHeader,
  noNav,
  noContainer,
  ...rest
}: Props) => {
  const user = useSelector((store: StoreState.All) => store.user);

  useEffect(() => {
    if (!isEmpty(user) && !user.extra.consent_processing_pd) {
      history.push('/filling-out-personal-data');
    }
  }, [user]);

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('token') ? (
          <div className={noContainer ? '' : 'container'}>
            {noHeader ? '' : <Header />}
            {noNav ? '' : <Nav {...props} />}
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: rest.location } }}
          />
        )
      }
    />
  );
};
