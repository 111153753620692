import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreState } from '../types';

const initialState: { users: StoreState.UserWithQuizResults[] } = { users: [] };

export const usersWithQuizResultSlice = createSlice({
  name: 'usersWithQuizResult',
  initialState,
  reducers: {
    load(state, action: PayloadAction<StoreState.UserWithQuizResults[]>) {
      state.users = action.payload;
    },
  },
});

export const { load } = usersWithQuizResultSlice.actions;
export default usersWithQuizResultSlice.reducer;
