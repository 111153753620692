export const ITEMS_HAS_ERRORED = 'ITEMS_HAS_ERRORED';
export const ITEMS_IS_LOADING = 'ITEMS_IS_LOADING';

export const userInfo = {
  USER_INFO_FETCH_SUCCESS: 'USER_INFO_FETCH_SUCCESS',
  USER_LOGOUT: 'USER_LOGOUT',
};

export const MY_COURSES_FETCH_SUCCESS = 'MY_COURSES_FETCH_SUCCESS';
export const MY_COURSES_NEXT_PAGE = 'MY_COURSES_NEXT_PAGE';
export const COURSE_CREATE_SUCCESS = 'COURSE_CREATE_SUCCESS';
export const COURSE_DETAIL_FETCH_SUCCESS = 'COURSE_DETAIL_FETCH_SUCCESS';
export const COURSE_DETAIL_UPDATE_SUCCESS = 'COURSE_DETAIL_UPDATE_SUCCESS';

export const CHAPTERS_FETCH_SUCCESS = 'CHAPTERS_FETCH_SUCCESS';
