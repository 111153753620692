import { toast } from 'react-toastify';
import store from 'store';
import API from './config';
import type {
  BaseInfoGetData,
  BaseInfoUpdateData,
  CoachStaffData,
  CommonInfoBlocData,
  Document,
  DocumentsGetData,
  InfoBlock,
  PaginatedResponse,
  Structure,
} from './types';

export function getMainInfo() {
  return API.get<BaseInfoGetData>(`/admin/base_information`, {
    headers: {},
  });
}

export function updateMainInfo(values: BaseInfoUpdateData) {
  return API.patch<BaseInfoGetData>(`/admin/base_information`, values, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function getDocuments() {
  return API.get<DocumentsGetData>(`/admin/documents?limit=150`, {
    headers: {},
  });
}

export function updatesDocuments(title: string) {
  return API.patch<DocumentsGetData>(
    `/admin/documents`,
    { title },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  );
}

export function addDocument(
  url: string,
  fileToUpload: any,
  previousFiles: any
) {
  return uploadAttachment(fileToUpload)
    .then((res) => {
      if (!res) {
        return;
      }
      let payload = { documents: [...previousFiles, res.data.id] };
      return API.patch(`${url}`, payload, {
        headers: {
          Authorization: `JWT ${store.get('token')}`,
        },
      });
    })
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Файл добавлен');
      return res;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function deleteDocument(
  url: string,
  id: number,
  previousFilesIds: Array<number>
) {
  return deleteAttachment('admin/document/', id)
    .then(() => {
      const payload = {
        documents: previousFilesIds.filter((item) => item !== id),
      };
      return API.patch(`${url}`, payload, {
        headers: {
          Authorization: `JWT ${store.get('token')}`,
        },
      });
    })
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Файл удален');
      return res;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function getCommonInfoBlockData(endpoint: string) {
  return API.get<CommonInfoBlocData>(`/admin/${endpoint}`, {
    headers: {},
  });
}

export function updateCommonInfoBlock(
  endpoint: string,
  title: string,
  text: string
) {
  return API.patch(
    `/admin/${endpoint}`,
    { title, text },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  )
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Сохранено');
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function getPersonEducationsList() {
  return API.get(`/admin/person_education?limit=100`, {
    headers: {},
  });
}

export function addPersonEducationRecord(name: string) {
  return API.post(
    `/admin/person_education`,
    { name },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  )
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Добавлено');
      return res;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function getTrainingAreasList(params: any) {
  return API.get(`/admin/area`, { params: params });
}

export function addTrainingArea(values: any) {
  return API.post(`/admin/area`, values, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  })
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Добавлено');
      return res;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function updateTrainingArea(id: number, values: any) {
  return API.patch(`/admin/area/${id}`, values, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function deleteTrainingArea(id: number) {
  return API.delete(`/admin/area/${id}`, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function getPersonsList() {
  return API.get(`/admin/person?limit=100`, {
    headers: {},
  });
}

export function addPerson(payload: any) {
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    if (Array.isArray(payload[key])) {
      for (let i = 0; i < payload[key].length; i++) {
        formData.append(key, payload[key][i]);
      }
    } else formData.append(key, payload[key]);
  });

  return API.post(`/admin/person`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `JWT ${store.get('token')}`,
    },
  })
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Сотрудник добавлен');
      return res;
    })
    .catch((err) => {
      for (const item in err.response.data) {
        toast.warn(`${item}: ${err.response.data[item][0]}`);
      }
    });
}

export function editPerson(id: number, payload: any) {
  const formData = new FormData();

  Object.keys(payload).forEach((key) => {
    if (!(payload[key] === null)) {
      if (Array.isArray(payload[key])) {
        for (let i = 0; i < payload[key].length; i++) {
          formData.append(key, payload[key][i]);
        }
      } else formData.append(key, payload[key]);
    }
  });

  return API.patch(`/admin/person/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `JWT ${store.get('token')}`,
    },
  }).then((res) => {
    if (!res) {
      return;
    }
    toast.success('Изменения сохранены');
    return res;
  });
}

export function deletePerson(id: number) {
  return API.delete(`/admin/person/${id}`, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function getManagers() {
  return API.get(`/admin/management`, {
    headers: {},
  });
}

export function addManagers(managers: Array<any>) {
  return API.patch(
    `/admin/management`,
    { managements: managers },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  )
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Сохранено');
      return res;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function getCoaches() {
  return API.get<CoachStaffData>(`/admin/coach_staff?limit=100`, {
    headers: {},
  });
}

export function patchCoachStaffTitle(title: string) {
  return API.patch(
    `/admin/coach_staff`,
    { title },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  );
}

export function addCoaches(coaches: Array<any>) {
  return API.patch(
    `/admin/coach_staff`,
    { coaches },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  )
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Сохранено');
      return res;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function getAttachments() {
  return API.get(`/admin/document`, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function uploadAttachment(attachment: any) {
  let formData = new FormData();
  formData.append('name', attachment.name.split('.').slice(0, -1).join('.'));

  formData.append('file', attachment);

  return API.post<Document>(`/admin/document`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function deleteAttachment(url: string, id: number) {
  return API.delete(`${url}${id}`, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  })
    .then(() => {
      toast.success('Файл удален');
      return id;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
      return null;
    });
}

export function getEducation() {
  return API.get<CommonInfoBlocData>(`/admin/education`, {
    headers: {},
  });
}

export function updateEducation(text: string) {
  return API.patch(
    `/admin/education`,
    {
      text: text,
    },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  )
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Сохранено');
      return res;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function getOrgStructureInfo() {
  return API.get<Structure>(`/admin/structure`, {
    headers: {
      Authorization: ``,
    },
  });
}

export function updateOrgStructure(
  persons: number[],
  text: string,
  title: string
) {
  return API.patch(
    `/admin/structure`,
    { persons, title, text },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  )
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Сохранено');
      return res;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function getEducationalPrograms() {
  return API.get(`/admin/educational_programm`, {
    headers: {},
  });
}

export function getCourseStructure(courseId: number) {
  return API.get(`/courses/${courseId}/navigate`, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}
export function getMainPageHeader() {
  return API.get(`/main_page/addr_and_number`);
}

export function updateMainPageHeader(values: any) {
  return API.patch(`/main_page/addr_and_number`, values, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function getMainPageContent() {
  return API.get(`/main_page/content`);
}

export function updateMainPageContent(values: any) {
  return API.patch(`/main_page/content`, values, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function getMainPageProgramms(params: any) {
  return API.get(`/main_page/programms`, {
    params: params,
  });
}

export function createMainPageProgramms(values: any) {
  return API.post(`/main_page/programms`, values, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function updateMainPageProgramms(id: number, values: any) {
  return API.patch(`/main_page/programms/${id}`, values, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function deleteMainPageProgramms(id: number) {
  return API.delete(`/main_page/programms/${id}`, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function getMainMenu() {
  return API.get(`/menu_items`, {});
}

export function getMainMenuItemById(id: number) {
  return API.get(`/menu_items/${id}`, {});
}

export function addMainMenuItem(name: string) {
  return API.post(
    `/menu_items`,
    { name },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  )
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Добавлено');
      return res;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function getMainMenuItemContent(id: number) {
  return API.get(`/menu_items/${id}/content`, {});
}

export function createMainMenuItemContent(id: number, text: string) {
  return API.post(
    `/menu_items/${id}/content`,
    { text },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  )
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Добавлено');
      return res;
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function updateMainMenuItemContent(id: number, text: string) {
  return API.patch(`/menu_items/${id}/content/`, text, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  })
    .then((res) => {
      if (!res) {
        return;
      }
      toast.success('Сохранено');
    })
    .catch((err) => {
      console.log(err);
      toast.warn('Ошибка');
    });
}

export function createInfoBlock(
  title: string,
  text: string,
  documents: number[],
  order: number
) {
  return API.post<InfoBlock>(
    '/admin/info_blocks',
    { title, text, documents, order },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  );
}

export function getInfoBlocks() {
  return API.get<PaginatedResponse<InfoBlock>>('/admin/info_blocks?limit=100');
}

export function deleteInfoBlock(blockId: number) {
  return API.delete(`/admin/info_blocks/${blockId}`, {
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}

export function updateInfoBlock(
  id: number,
  title: string,
  text: string,
  documents: number[],
  order: number
) {
  return API.patch<InfoBlock>(
    `/admin/info_blocks/${id}`,
    { title, text, documents, order },
    {
      headers: {
        Authorization: `JWT ${store.get('token')}`,
      },
    }
  );
}

export function getPersonalDataList(params: any) {
  return API.get(`/users/personal_data_processing/`, {
    params: params,
    headers: {
      Authorization: `JWT ${store.get('token')}`,
    },
  });
}
