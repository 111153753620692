import React from 'react';
import { useSelector } from 'react-redux';
import SimpleMenu from '../components/SimpleMenu';
import { StoreState } from '../store/types';

const Header = () => {
  const user = useSelector((state: StoreState.All) => state.user);
  return <SimpleMenu user={user} />;
};

export default Header;
