import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  createStyles({
    preloader: {
      position: 'fixed',
      height: '100vh',
      zIndex: 2,
      width: '100%',
      top: 0,
      left: 0,
      display: 'flex',
    },
    circular: {
      margin: 'auto',
    },
  })
);

const Preloader = () => {
  const classes = useStyles();

  return (
    <div className={classes.preloader}>
      <CircularProgress className={classes.circular} />
    </div>
  );
};

export default Preloader;
