import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from './api/history';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { isEmpty } from 'lodash';
import PrivatePages from './PrivatePages';
import PublicPages from './PublicPages';
import useOnMount from './hooks/useOnMount';
import { userInfoFetch } from './store/actions/user';
import { StoreState } from './store/types';

const App = () => {
  const user = useSelector((store: StoreState.All) => store.user);
  const dispatch = useDispatch();

  useOnMount(() => {
    if (isEmpty(user)) {
      dispatch(userInfoFetch('/users/my_data/'));
    }
  });

  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          <Route
            path={[
              '/',
              '/programs',
              '/trainers',
              '/info',
              '/custom-page/:id',
              '/contacts',
            ]}
            component={PublicPages}
            exact
          />
          <Route component={PrivatePages} />
        </Switch>
      </Router>
      <ToastContainer autoClose={10000} closeOnClick />
    </React.Fragment>
  );
};

export default App;
