import React, { useState, useEffect } from 'react';
import HeaderPublicPage from '../views/PublicPages/HeaderPublicPage';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getMainMenuItemById } from '../api/admin';

const CustomPage = () => {
  const [pageName, setPageName] = useState<any>([]);
  const { id } = useParams<any>();
  let match = useRouteMatch();

  useEffect(() => {
    getMainMenuItemById(id)
      .then((res) => {
        if (!res) {
          return;
        }
        setPageName(res.data);
      })
      .catch((err) => {
        toast.error('Ошибка!');
      });
  }, [id]);

  return (
    <>
      <HeaderPublicPage />
      <div className="container">
        <div className="pagination">
          <Link to="/">Главная</Link>
          <span>→</span>
          <Link to={`${match.url}`}>{pageName.name}</Link>
        </div>
        <h1>{pageName.name}</h1>
      </div>
    </>
  );
};

export default CustomPage;
