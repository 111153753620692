import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { YMInitializer } from 'react-yandex-metrika';
import { store } from './store/store';

import Moment from 'react-moment';
import 'moment/locale/ru';
Moment.globalLocale = 'ru';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
      <YMInitializer
        accounts={[68007697]}
        options={{ webvisor: true }}
        version="2"
      />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
