import { TextField, TextFieldProps } from '@material-ui/core';
import { useField } from 'formik';
import InputMask from 'react-input-mask';

const FormikPhoneField = ({
  name,
  className,
  label,
}: TextFieldProps & { name: string }) => {
  const [field] = useField(name);

  // return <TextField {...props} {...field} />;

  return (
    <InputMask mask="+7 (999) 999-99-99" className={className} {...field}>
      <TextField label={label} />
    </InputMask>
  );
};

export default FormikPhoneField;
