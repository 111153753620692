import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextFieldProps,
} from '@material-ui/core';
import { useField } from 'formik';

const FormikPrivacyCheckbox = ({
  name,
  className,
  label,
}: TextFieldProps & { name: string }) => {
  const [field] = useField(name);

  return (
    <FormControl component="fieldset" className={className + ' privacy'}>
      <FormLabel component="legend"></FormLabel>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          control={<Checkbox color="primary" {...field} />}
          label={
            <p>
              Нажимая на кнопку, я даю{' '}
              <a
                href="https://docs.google.com/document/d/1QvR1WJ88hq9WELfsvsOQjSq9KFWRrfXZ"
                target="_blank"
                rel="noreferrer"
              >
                согласие
              </a>
              на обработку моих персональных данных в соответствии с
              <a
                href="https://docs.google.com/document/d/1usdqGd7bgYgAwoYU-uj52lb442e9hhWt_C0MnYKH17E"
                target="_blank"
                rel="noreferrer"
              >
                Политикой в отношении обработки персональных данных
              </a>
            </p>
          }
          labelPlacement="end"
          style={{ alignItems: 'flex-start', marginRight: '0' }}
        />
      </FormGroup>
    </FormControl>
  );
};

export default FormikPrivacyCheckbox;
