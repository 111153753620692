import axios from 'axios';
import { history } from './history';
import store from 'store';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
});

api.interceptors.response.use(
  function (response) {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  function (error) {
    const errorResponse = error.response;
    if (errorResponse && isTokenExpiredError(errorResponse)) {
      return resetTokenAndReattemptRequest(error);
    }
    // If the error is due to other reasons, we just throw it back to axios
    return Promise.reject(error);
  }
);

function isTokenExpiredError(errorResponse) {
  return (
    errorResponse.status === 401 &&
    errorResponse.data.code === 'token_not_valid'
  );
}

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers: { (token: string): void }[] = [];

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
    const resetToken = getRefreshToken(); // Your own mechanism to get the refresh token to refresh the JWT token
    if (!resetToken) {
      // We can't refresh, throw the error anyway
      return Promise.reject(error);
    }
    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise((resolve) => {
      /* We need to add the request retry to the queue
    since there another request that already attempt to
    refresh the token */
      addSubscriber((access_token: string) => {
        errorResponse.config.headers.Authorization = 'JWT ' + access_token;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await axios({
        method: 'post',
        url: `${BASE_URL}/users/token/refresh/`,
        data: {
          refresh: resetToken,
        },
      });

      if (!response.data) {
        return Promise.reject(error);
      }
      const newToken = response.data.access;
      store.set('token', newToken); // save the newly refreshed token for other requests to use
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(newToken);
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

function onAccessTokenFetched(access_token: string) {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback: { (token: string): void }) {
  subscribers.push(callback);
}

function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

function getRefreshToken() {
  const resetToken = store.get('refreshToken');
  const payload = parseJwt(resetToken);
  if (Date.now() >= payload.exp * 1000) {
    history.push('/login');
    store.remove('token');
    store.remove('refreshToken');
    return false;
  }
  return resetToken;
}

export default api;
