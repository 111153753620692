import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { StoreState } from '../store/types';

const trainerLinks = [
  { text: 'Мои курсы', link: '/courses' },
  { text: 'Все курсы', link: '/other-courses' },
  { text: 'Экзамены', link: '/exams' },
  // { text: 'Группы', link: '/groups' },
];

const studentLinks = [
  { text: 'Мои курсы', link: '/my-courses' },
  { text: 'Все курсы', link: '/all-courses' },
];

const Nav = (props) => {
  const user = useSelector((store: StoreState.All) => store.user);
  const [selectedItem, setSelectedItem] = useState(0);
  const [links, setLinks] = useState(studentLinks);

  useEffect(() => {
    if (user.groups && user.groups.filter((g) => g.name === 'coach').length) {
      setLinks(trainerLinks);
    } else {
      setLinks(studentLinks);
    }
  }, [user.groups]);

  useEffect(() => {
    const curLinkIndex = links.findIndex((el) =>
      props.match.path.includes(el.link)
    );
    if (curLinkIndex !== -1) {
      setSelectedItem(curLinkIndex);
    }
  }, [props.match, links]);
  return (
    <React.Fragment>
      <nav className="nav">
        <ul className="nav__list">
          {links.map((link, index) => (
            <li className="nav__item" key={index}>
              <Link
                to={link.link}
                className={`nav__link ${
                  selectedItem === index ? 'nav__link_active' : ''
                }`}
                onClick={() => {
                  setSelectedItem(index);
                }}
              >
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
        <hr className="hr-line" />
      </nav>
    </React.Fragment>
  );
};

export default Nav;
